@import 'src/styles/library/index';

.HandComponent {
    --smallCardColumnsWidth: calc(var(--cardWidth) * 0.23);

    display: grid;
    grid-template-columns: repeat(calc(var(--cards) - 1), var(--smallCardColumnsWidth)) var(--cardWidth);
    width: calc(calc(var(--cards) - 1) * var(--smallCardColumnsWidth) + var(--cardWidth));
    margin: auto;

    & > div {
        min-width: 0;
    }

    &:not(.vertical) {
        --cards: 13;
        margin: {
            top: 0.7em;
        }
    }

    &.vertical {
        $term1: 'var(--handWidth) - var(--cardWidth)';
        $term2: 'var(--cards) / (var(--cards) - 1)';

        --cards: 4;
        --width: calc(calc(#{$term1}) * calc(#{$term2}));

        grid-template-columns: repeat(var(--cards), 1fr);
        width: var(--width);
        max-width: calc(4 * var(--cardWidth) + 3 * var(--cardWidth) * 0.04);
        height: var(--cardHeight);

        & .suit {
            position: relative;
            flex: 0 0 25%;
            flex-direction: column;
            align-items: center;

            & .suit-inner {
                position: absolute;
                width: var(--cardWidth);

                & > div {
                    width: 100%;
                }
            }
        }

        & .normalCard {
            --marginTop: -0.76;

            &:not(:first-child) {
                margin-top: calc(var(--cardHeight) * var(--marginTop));
                transition: margin-top 0.3s ease-in-out;
            }

            &.selected {

                & .card {
                    background: {
                        color: rgb(253, 247, 157);
                    };
                }
                & + .normalCard {
                    --marginTop: -0.6;
                }
            }
        }
    }

    &.horizontal {

        & .normalCard {

            &.selected {
                margin-top: calc(var(--cardHeight) * -0.3);

                & .card {
                    background: {
                        color: rgb(253, 247, 157);
                    };
                }
            }
        }
    }

    &.dynamic-card-columns-width {
        --smallCardColumnsWidth: calc((var(--handWidth) - var(--cardWidth)) / 12);
    }
}
