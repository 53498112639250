@import 'src/styles/library/index';

.TooltipComponent {
    @include auxiliaryMessageBaseLayout;

    font-size: 2.2rem;

    & .title {
        background-image: none;
        color: red;
        text-shadow: none;
    }

    & .text {
        font-size: 0.9em;
    }
}
