header,
pheader,
psection {
    color: black;
    display: block;
    margin: 0;
    padding: 0;
    text-align: left;
}

& pheader {
    font-weight: bold;
    margin-left: 5px;
    padding-top: 2px;
    padding-left: 10px;
    padding-right: 2px;
    padding-bottom: 0;
}

& header {
    padding: 0;
    font-weight: bold;
}

& subheader {
    color: #666;
    display: block;
    font-variant: small-caps;
    line-height: 90%;
    margin-top: 2rem;
    text-align: left;
    text-transform: uppercase;
}

& a {
    &.glossarylink {
        font-style: italic;
        text-decoration: none;
        color: inherit;
    }

    &.indexglossarylink {
        text-decoration: none;
        color: inherit;
    }

    &.intro {
        color: #fffafa;
    }
}

& table {
    &.ordinary {
        height: 100%;
        margin: auto;
        //     width: 100%;
        td {
            width: 100%;
            text-align: center;
            vertical-align: middle;
        }
    }

    &.ordinary_top {
        margin-top: 20px;
        height: 100%;
        width: 100%;

        & tr {
            vertical-align: top;
        }
    }
}

& img {
    &.ordinary {
        width: 70%;
    }

    &.play_deal_graphic {
        width: 44%;
    }

    &.allcardsbysuit {
        width: 64%;
    }

    &.quiz_graphic {
        width: 33%;
    }

    &.graphic_90 {
        width: 75%;
        min-width: 100%;
    }

    &.graphic_100 {
        width: 100%;
        min-width: 100%;
    }

    &.graphic_40 {
        width: 40%;
        min-width: 80%;
    }

    &.graphic_50 {
        width: 40%;
        min-width: 80%;
    }

    &.graphic_60 {
        width: 60%;
        min-width: 80%;
    }

    &.graphic_80 {
        width: 80%;
        min-width: 100%;
    }
}

& b {
    font-weight: bold;
}

& font {
    &.suitred,
    &.suitrednosize,
    &.suitredExtraLarge {
        color: red;
    }

    &.suitblack,
    &.suitblacknosize,
    &.suitblackExtraLarge {
        color: black;
    }
}

& input {
    &.quiz_submit_OK {
        display: inline-block;
        font-size: 1em;
        //       zoom: 1; /* zoom and *display = ie7 hack for display:inline-block */
        vertical-align: baseline;
        margin: 0 2px;
        outline: none;
        cursor: pointer;
        text-align: center;
        text-decoration: none;
        font-weight: bold;
        text-transform: uppercase;
        -webkit-border-radius: 0.5em;
        border-radius: 0.5em;
        -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
        max-width: 100px;
        min-width: 100px;
        color: #606060;
        border: solid 1px #b7b7b7;
        background: #fff;
        background: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#ededed));
        background: -moz-linear-gradient(top, #fff, #ededed);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ededed');
        &:hover {
            background: #ededed;
            background: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#dcdcdc));
            background: -moz-linear-gradient(top, #fff, #dcdcdc);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#dcdcdc');
        }
        &:active {
            color: #999;
            background: -webkit-gradient(linear, left top, left bottom, from(#ededed), to(#fff));
            background: -moz-linear-gradient(top, #ededed, #fff);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ededed', endColorstr='#ffffff');
        }
    }
}

& ul {
    list-style-type: disc;
    margin-left: 10px;

    &.ordinary {
        list-style-type: disk;
        margin-left: 15px;
    }

    &.quiz_disk {
        list-style-type: disk;
        margin-left: 20px;
    }

    & li {
        margin-top: 10px;

        &.quiz_disk {
            & a {
                color: #000;
                list-style-type: disk;
                text-decoration: none;
            }
        }
    }
}
