@import 'src/styles/library/index';

.SettingsComponent {
    display: flex;
    justify-content: center;
    align-items: center;

    &,
    & .backdrop {
        z-index: $z-settings;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    & .backdrop {
        pointer-events: none;
    }

    & .modal {
        z-index: $z-settings + 1;
        width: 90%;
        max-width: 600px;
        background: #f1ecea;
        border-radius: 10px;
        border: 1px solid black;
        color: black;
        font-size: 22px;
        font-weight: bold;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
    }

    & .title {
        text-align: center;
        padding: 0.3em;
        border-bottom: 1px solid #555555;
    }

    & .body {
        display: flex;
        flex-wrap: wrap;
        row-gap: 0.7em;
        padding: 1em;
    }

    & .checkbox {
        flex: 0 0 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        line-height: 1;

        &:before {
            $size: 1em;

            content: '';
            display: block;
            width: $size;
            height: $size;
            margin-right: 0.4em;
            margin-top: -0.2em;
            background: {
                size: contain;
                repeat: no-repeat;
                position: center;
                image: url('../../../public/images/x-mark-hi2.png');
            }
        }

        &.checked {
            &:before {
                background: {
                    image: url('../../../public/images/green-checkmark.png');
                }
            }
        }

        &:nth-child(1),
        &:nth-child(2) {
            flex: 0 0 50%;
        }
    }

    & .button-group {
        flex: 0 0 100%;
        margin: 0.5em 0;
    }

    & .speeds {
        $borderRadius: 0.2em;

        display: grid;
        grid-template-columns: repeat(5, 1fr);
        margin: 0.1em 0 0;
        border-radius: $borderRadius;
        background: rgba(0, 0, 0, 0.1);

        & .speed {
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 0;
            background: transparent;
            border-radius: $borderRadius;
            padding: 0.45em 0 0.3em;
            font-size: 0.8em;
            font-weight: normal;
            cursor: pointer;

            &.selected {
                background: rgba(0, 0, 0, 0.2);
            }
        }
    }

    & .footer {
        display: flex;
        justify-content: center;
        column-gap: 1em;
        padding-bottom: 0.5em;

        & button {
            @include button;

            width: 20%;
            font-size: 0.7em;
        }
    }
}
