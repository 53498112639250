@import 'src/styles/library/index';

.SharkTrickHistoryComponent {
    $trickWidth: 100 / 3 * 1%;
    $trickHeight: 120px;
    $padding: 1px;
    $tricksPerRow: 4;
    $fontSize: 24px;

    z-index: 100;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    counter-reset: section;

    & .tricks {
        display: flex;
        flex: 1 1 auto;
        overflow: auto;
        flex-wrap: wrap;
    }

    & .bridgePositions,
    & .trick {
        @include aspectRatio(
            (
                100: 85,
            ),
            false
        );

        position: relative;
        flex: 0 0 $trickWidth;
        border: {
            width: 1px;
            style: solid;
            color: black;
        }
        background: white;

        & > div {
            $padding: 2px;

            position: absolute;
            text-transform: uppercase;
            padding: $padding;
            font: {
                size: #{'min(3.5rem, 26px)'};
                weight: 700;
            }

            &.top {
                @include centerH;

                top: 0;
            }

            &.right {
                @include centerV;

                right: 0;
            }

            &.bottom {
                @include centerH;

                bottom: 0;
            }

            &.left {
                @include centerV;

                left: 0;
            }
        }
    }

    & .trick {
        position: relative;

        & .card {
            @include suits;

            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            font-family: 'Helvetica', sans-serif;

            &.diamonds {
                letter-spacing: -0.2rem;

                & span {
                    margin-left: 0;
                }
            }

            &:before {
                display: inline-block;
                margin: {
                    left: 0.1rem;
                }
                font-size: 0.8em;
            }

            & span {
                color: black;
                font-weight: 500;
                letter-spacing: #{'min(-0.2rem, 2px)'};
                margin-right: 0.2rem;
                white-space: nowrap;
            }

            &:after {
                margin: {
                    left: 0.1rem;
                }
                font: {
                    size: 0.8em;
                }
            }

            &.leader {
                padding: {
                    right: 3px;
                }
                border: {
                    width: 1px;
                    style: solid;
                    color: black;
                }
            }
        }

        & .trick-index {
            $size: 2rem;

            position: absolute;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            top: 0;
            left: 0;
            width: $size;
            height: $size;
            max-width: 26px;
            max-height: 26px;
            min-width: 22px;
            min-height: 22px;
            font: {
                size: #{'min(3.5rem, 16px)'};
                weight: 600;
            }
            background: black;
            color: $colorFontInverted;

            &:before {
                counter-increment: section;
                content: counter(section);
            }
        }

        &:after {
            font-size: 16px;
        }

        &.winner {
            $triangleWidth: 13px;
            $triangleHeight: $triangleWidth;
            $triangleColor: black;
            $margin: 10px;

            background: lighten(darkseagreen, 18);

            &-top,
            &-right,
            &-bottom,
            &-left {
                &:after {
                    @include centerVH;

                    content: '';
                    position: absolute;
                    z-index: 2;
                }
            }

            &-top {
                &:after {
                    @include shark_icon($icon-chevron-up);
                }
            }

            &-right {
                &:after {
                    @include shark_icon($icon-chevron-right);
                }
            }

            &-bottom {
                &:after {
                    @include shark_icon($icon-chevron-down);
                }
            }

            &-left {
                &:after {
                    @include shark_icon($icon-chevron-left);
                }
            }
        }
    }
}
