@mixin dynInput {
    height: px($baseButtonHeight);
    border: {
        width: 1px;
        style: solid;
        color: $colorFontInverted;
    }
    background: none;
    color: $colorFontInverted;
    font: {
        size: vw(24px);
    }

    &:focus,
    &:focus:active {
        outline: 0 !important;
    }
}
