@import 'src/styles/library/index';
.WalkThroughComponent {
    @include getFontSize(1.25);
    position: relative;
    overflow: auto;

    & .index-buttons,
    & .walk-through-iframe,
    & .walk-through-iframe iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: white;
    }

    & .index-buttons {
        --gap: calc(5 * var(--buttonPaddingV));

        z-index: 2;
        display: flex;
        flex-direction: column;
        row-gap: var(--gap);
        padding: calc(var(--gap) * 1.5);
        overflow: auto;
    }

    & .walk-through-iframe {
        z-index: 1;
    }

    & .ButtonComponent {
        display: block;
        font-size: px(30);
    }

    & .SharkBoardReviewComponent {
        display: flex;
        flex-direction: column;
    }
}
