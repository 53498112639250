@import 'src/styles/library/index';
@import 'src/index';

.BiddingLadderComponent {
    $borderColor: #eee;
    $thinBorder: 1px solid $borderColor;
    $thickBorder: 5px solid $borderColor;

    height: 100%;
    display: flex;
    flex-direction: column;
    border-right: 1px solid black;
    background: white;

    & .bids,
    & .stakes {
        display: grid;
        grid-template-columns: px($biddingLadderWidth);

        & img {
            width: 100%;
        }

        & .bid {
            @include aspectRatio(
                (
                    3: 2,
                )
            );

            position: relative;
            min-width: 0;
            background: {
                size: 0 0;
            }

            &:after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: {
                    image: inherit;
                    repeat: no-repeat;
                    size: contain;
                    position: center;
                }
            }

            &.is-disabled {
                background-color: #eee;

                &:after {
                    opacity: 0.5;
                }
            }

            &.is-active {
                cursor: pointer;
            }
        }

        & .BonusIndicator {
            min-width: 0;
            min-height: 0;
            background: #eee;

            &.highlighted {
                background: orange;
            }
        }
    }

    & .bids {
        @include noScrollbar;

        flex: 0 1 100%;
        width: 100%;
        min-width: 0;
        min-height: 0;
        overflow-y: auto;
        overflow-x: hidden;

        & .BonusIndicator {
            border-left: 1px solid black;
        }
    }

    & .stakes {
        position: relative;
        flex: 1 1 auto;
        border-top: 1px solid #eee;
    }

    &.is-active {
    }

    &:not(.show-bonus-indicator) {
        & .bid {
            border-bottom: $thinBorder;

            &:nth-child(5n) {
                border-bottom: $thickBorder;
            }
        }
    }

    &.show-bonus-indicator {
        & .bids,
        .stakes {
            grid-template-columns: px($biddingLadderWidth) px($bonusIndicatorWidth);
        }

        & .bid {
            border-bottom: $thinBorder;

            &:nth-child(10n -1) {
                border-bottom: $thickBorder;
            }
        }
    }

    & .arrow {
        position: absolute;
        right: px(1);
        bottom: 0;
        width: px($bonusIndicatorWidth);
        height: px(200);
        background: {
            size: 70% 100%;
            position: right;
            repeat: no-repeat;
        }
    }
}
