@function sqrt($r) {
    $x0: 1;
    $x1: $x0;

    @for $i from 1 through 10 {
        $x1: $x0 - ($x0 * $x0 - abs($r)) / (2 * $x0);
        $x0: $x1;
    }

    @return $x1;
}

@function strip-unit($num) {
    @return $num / ($num * 0 + 1);
}

@function px($px, $baseFontSize: 16) {
    @return strip-unit($px) / $baseFontSize * 1rem;
}

@function hexToRgb($hexColor) {
    @return #{'#{red($hexColor), green($hexColor), blue($hexColor)}'};
}

@function xrgb($rgbColorFragment) {
    @return #{'rgb(var(#{$rgbColorFragment}))'};
}

@function xrgba($rgbColorFragment, $opacity) {
    @return #{'rgba(var(#{$rgbColorFragment}), #{$opacity})'};
}

@function xtransparentize($xrgb, $opacity) {
    @return xrgba(str-slice('#{$xrgb}', 9, -3), $opacity);
}
