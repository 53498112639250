@import 'src/styles/library/index';

.AuctionComponent {
    position: relative;
    background: white;
    margin-left: 4px;

    &:not(.walkThrough) {
        cursor: pointer;
    }
}
