@import 'src/styles/library/index';

.SharkTrayComponent {
    @include shark_tray;

    width: 100%;

    & .general,
    & .justMe {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    & .general {
        height: 3rem;
    }

    & .justMe {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0 1rem;

        & _button {
            @include shark_dynamicButton;
        }
    }

    & .player {
        display: flex;
        font-size: 1.7rem;
        word-break: break-word;
        white-space: normal;
        max-height: 2em;
        overflow: hidden;
    }

    & > * {
        position: relative;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 10px;
        white-space: nowrap;
    }

    & .isMe,
    & .isDummy {
        margin: {
            top: 0.1em;
            left: $basePadding;
        }
        font: {
            size: 0.8em;
        }
    }

    & .actions {
        display: flex;
        margin-right: 1rem;

        & button {
            position: relative;
            color: $colorFontInverted;
            font: {
                size: 1.5rem;
            }
        }
    }

    & .bridgePosition {
        margin: {
            right: 5%;
        }
        font: {
            size: 1.5rem;
            weight: 700;
        }
    }

    & .toggleShowCards {
        @include shark_buttonIconOnly($icon-flip);
    }

    & .rotateTable {
        @include shark_buttonIconOnly($icon-eye);
    }

    & .isActive {
        @include shark_buttonIconOnly($icon-loop2, 1.2em);

        display: none;
        margin: {
            left: $basePadding/2;
        }
        color: $colorFontInverted;
        cursor: default;
        animation: 4s rotateRight infinite linear;
        @keyframes rotateRight {
            100% {
                transform: rotate(360deg);
            }
        }
    }

    &.isHighlighted {
        &.currentPlayerDisplay {
            &-background {
                &:after {
                    background: inherit;
                }
            }

            &-border {
                &:after {
                    border: {
                        width: 2px;
                        style: solid;
                        color: $colorFontInverted;
                    }
                }
            }

            &-animation {
                & .isActive {
                    display: block;
                }
            }
        }
    }

    & button {
        &.manualDeal {
            @include shark_dynamicButton;
        }

        &.showInvite,
        &.sendInvite,
        &.closeInvite {
            @include shark_dynamicButton;
            @include shark_iconButton;
        }

        &.showInvite {
            font-size: 1.7em;
            font-weight: 400;
        }

        &.sendInvite {
            @include shark_buttonIconOnlyIcon($icon-paperplane);

            margin-right: px(5);
        }

        &.closeInvite {
            @include shark_buttonIconOnlyIcon($icon-cross);

            font-size: 0.8em;
        }

        @for $i from 0 through 11 {
            &.button-#{$i} {
                order: $i + 1;
            }
        }
    }

    & .invite {
        position: relative;

        & .input {
            position: absolute;
            bottom: 0.3em;
            right: 100%;
            margin-right: px(5);

            & .storedEmails {
                max-height: 6em;
                overflow-x: auto;
            }

            & .storedEmail {
                background: black;
                border: 1px solid white;
                padding: 0.4em 0.5em;
            }
        }

        & input {
            @include dynInput;

            width: 20rem;
            background: black;
            color: white;
            padding: 0 0.5em;

            &.invalid {
                color: red;
            }
        }
    }

    & .info {
        @include centerHV;

        position: absolute;
        height: 3rem;
        font-size: 1.5rem;

        & .board {
            display: flex;
            align-items: baseline;

            & .boardLabel {
                font-weight: bold;
                margin-right: 0.5rem;
            }

            & .boardValue {
                font-size: 0.8em;
            }
        }
    }

    & .timeToGameEnd {
        min-width: 4rem;
        margin: {
            top: 0.5rem;
        }
    }

    & .buttonsCenter {
        display: flex;
        align-items: center;
        column-gap: 0.5rem;
        width: 100%;
    }

    & .buttonsRight {
        & button {
            @include shark_iconButton;

            margin: {
                left: 1rem;
            }

            &.false {
                &:after {
                    @include centerHV;

                    content: '';
                    position: absolute;
                    width: 70%;
                    height: 0.2rem;
                    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
                    background: white;
                    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
                    transform-origin: center center;
                }
            }
        }

        & .micro {
            @include shark_buttonIconOnlyIcon($icon-microphone);

            //&.false {
            //  @include shark_buttonIconOnlyIcon($icon-microphone-slash);
            //}
        }

        & .sound {
            @include shark_buttonIconOnlyBase;
            @include shark_buttonIconOnlyIcon($icon-volume-medium);

            //&.false {
            //  @include shark_buttonIconOnlyIcon($icon-volume-mute2);
            //}
        }

        & .camera {
            @include shark_buttonIconOnlyIcon($icon-video);
        }

        & .showMetaData {
            @include shark_buttonIconOnlyIcon($icon-info);

            visibility: visible;
            pointer-events: all;
        }

        & .showSettings {
            @include shark_buttonIconOnlyIcon($icon-settings, 1.5rem);
        }
    }

    .Hand.fan + & {
        margin: {
            top: -1.2rem;
        }
    }
}
