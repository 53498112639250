@import 'src/styles/library/index';

.SharkBoardReviewComponent {
    $minColWidth: 320px;
    $maxColWidth: 400px;
    $distance: 5px;

    display: grid;
    grid-template-columns: 1fr;
    column-gap: 1rem;
    row-gap: 3rem;
    margin: 0 1rem;

    & .short-table {
        grid-row: 2 / 3;
    }

    & .auctionBox-trickHistory {
        justify-self: center;
        grid-row: 3 / 4;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        column-gap: $distance;
        row-gap: $distance;
        justify-content: space-evenly;
        max-width: 330px;

        & h3 {
            font-size: 2.2em;
            margin-bottom: px(15);
        }
    }

    & .board-stats {
        grid-row: 1 / 2;
    }

    & .AuctionBox {
        position: relative;
        height: auto;
        margin-bottom: 10px;

        & .auction {
            & td.empty:last-child {
                border-color: black;

                &:before {
                    color: black;
                }
            }

            & th {
                &.bridgePosition {
                    height: 26px !important;
                    font-size: 22px !important;
                    line-height: normal;
                }
            }

            & th,
            & tbody:not(.setWidths) td,
            & tbody:not(.setWidths) td .SharkBidComponent {
                height: 26px !important;
                font-size: 16px !important;
                line-height: normal;
            }

            & tbody:not(.setWidths) td .SharkBidComponent svg {
                height: 60%;
            }

            margin-bottom: 3px;
        }

        flex-basis: #{vw(300px)};
    }

    & .TrickHistory {
        margin-bottom: 10px;
    }

    &.stand-alone {
        grid-template-columns: 1fr 2fr;
        row-gap: 10px;
        margin: 0;

        & .short-table {
            grid-column: 1 / 2;
            grid-row: 1 / 2;
        }

        & .auctionBox-trickHistory {
            grid-row: 1 / 3;
            display: grid;
            grid-template-columns: auto auto;
            grid-template-rows: auto;
            column-gap: 10px;
            max-width: none;
        }

        & .board-stats {
            grid-column: 1 / 2;
            grid-row: 2 / 3;
        }

        @include breakpointBelow(1024px) {
            grid-template-columns: 1fr 1fr;

            & .short-table {
                grid-column: 1 / 2;
                grid-row: 1 / 2;
            }

            & .auctionBox-trickHistory {
                grid-column: 1 / 3;
                grid-row: 2 / 3;
                grid-template-columns: auto auto;
            }

            & .board-stats {
                grid-column: 2 / 3;
                grid-row: 1 / 2;
            }
        }

        @include breakpointBelow(620px) {
            grid-template-columns: 1fr;

            & .short-table {
                grid-column: 1 / 2;
                grid-row: 1 / 2;
            }

            & .auctionBox-trickHistory {
                grid-column: 1 / 2;
                grid-row: 2 / 3;
                grid-template-columns: auto;
            }

            & .board-stats {
                grid-column: 1 / 2;
                grid-row: 3 / 4;
            }
        }
    }

    &:not(.stand-alone) {
        @include breakpointBelow(950px) {
            --dynamic-ratio: 0.67px;
        }

        @include breakpointAbove(1024px) {
            --dynamic-ratio: 0.73px;
        }

        @include breakpointBelow(768px) {
            & .column-2-3 {
                flex-wrap: wrap;
            }
        }

        @include breakpointBelow($maxColWidth) {
            & .column {
                flex: 0 0 100%;
                max-width: 100%;
            }

            & .column-2-3 {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                min-width: 0;

                & .AuctionBox {
                    order: 1;

                    & + .AuctionBox {
                        order: 3;
                    }
                }

                & .TrickHistory {
                    order: 2;

                    & + .TrickHistory {
                        order: 4;
                    }
                }
            }
        }

        @include breakpointAbove(760px) {
            grid-template-columns: 3fr 2fr;
            grid-template-rows: auto auto;

            & .board-stats {
                grid-column: 1 / 2;
                grid-row: 1 / 2;
            }

            & .auctionBox-trickHistory {
                grid-column: 2 / 3;
                grid-row: 1 / 3;
            }
        }

        @include breakpointAbove(1024px) {
            grid-template-columns: 1fr 1fr 1fr;

            & .short-table {
                grid-column: 1 / 2;
                grid-row: 1 / 2;
            }

            & .auctionBox-trickHistory {
                grid-column: 2 / 3;
                grid-row: 1 / 2;
            }

            & .board-stats {
                grid-column: 3 / 4;
                grid-row: 1 / 2;
            }
        }
    }
}
