@import 'src/styles/library/index';

.TrickCounterComponent {
    @include sharkTray;
    @include getFontSize(1);

    position: relative;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 1em 0.5em 0.5em;

    & .trickCount,
    & .contractStake {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        flex: 0 0 auto;
    }

    & .trickCount {
        display: grid;
        grid-template-columns: auto auto;
        column-gap: 0.4em;
        row-gap: 0.2em;
        position: relative;
        z-index: 2;
        white-space: pre;
    }

    & .contractStake {
        position: relative;
        z-index: 2;

        & > div {
            display: flex;
        }
    }

    & .contract,
    & .stake {
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
    }

    & .SharkBidComponent {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2em;
        height: 1.2em;
    }

    & .contract {
        & .SharkBidComponent {
            background: white;

            & svg {
                flex: 0 0 100%;
            }

            &:not(.noTrump) {
                padding: {
                    left: 0.4em;
                }
            }
        }
    }

    & .stake {
        & > section {
            border: 1px solid white;
        }
    }

    & .declarer {
        margin: {
            right: 0.4em;
        }

        &:after {
            content: ':';
        }
    }

    & .board {
        margin-bottom: 0.2em;

        & .boardLabel {
            margin-right: 0.4em;
        }
    }
}
