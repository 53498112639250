@import 'src/styles/library/index';

.TableInfoComponent {
    --columns: 2;

    display: grid;
    grid-template-columns: auto 1fr;
    gap: 0.4em;
    font-size: px(27);
    color: white;

    & .text {
        grid-column: 1 / -1;
    }

    & .label {
        & span {
            position: relative;
        }
    }

    & .value {
        & .contract {
            position: relative;
            display: inline-flex;
            align-items: center;
            column-gap: 0.1em;
            padding: 0 px(8);
            font-weight: bold;
            color: black;

            &:before {
                content: '';
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                background-color: white;
            }

            & > * {
                position: relative;
                z-index: 2;
            }

            & .suit {
                min-height: 0;
                height: 0.7em;
                overflow: hidden;
                font-size: 1.3em;
                font-family: 'Times New Roman', sans-serif;
                line-height: 0.7;
            }

            & .level {
                font-family: var(--fontFamily);
            }
        }
    }

    &.left {
        & .label {
            &:first-child {
                & span {
                    @include dealerUnderline;
                }
            }
        }
    }
}
