@import 'variables';

@font-face {
    font-family: '#{$icomoon-font-family}';
    src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?6ivfj3');
    src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?6ivfj3#iefix') format('embedded-opentype'),
        url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?6ivfj3') format('truetype'),
        url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?6ivfj3') format('woff'),
        url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?6ivfj3##{$icomoon-font-family}') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$icomoon-font-family}' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-question {
    &:before {
        content: $icon-question;
    }
}
.icon-check {
    &:before {
        content: $icon-check;
    }
}
.icon-chevron-left {
    &:before {
        content: $icon-chevron-left;
    }
}
.icon-chevron-right {
    &:before {
        content: $icon-chevron-right;
    }
}
.icon-question-circle {
    &:before {
        content: $icon-question-circle;
    }
}
.icon-chevron-up {
    &:before {
        content: $icon-chevron-up;
    }
}
.icon-chevron-down {
    &:before {
        content: $icon-chevron-down;
    }
}
.icon-info {
    &:before {
        content: $icon-info;
    }
}
.icon-microphone {
    &:before {
        content: $icon-microphone;
    }
}
.icon-microphone-slash {
    &:before {
        content: $icon-microphone-slash;
    }
}
.icon-credits {
    &:before {
        content: $icon-credits;
    }
}
.icon-flip {
    &:before {
        content: $icon-flip;
    }
}
.icon-spades {
    &:before {
        content: $icon-spades;
    }
}
.icon-clubs {
    &:before {
        content: $icon-clubs;
    }
}
.icon-diamonds {
    &:before {
        content: $icon-diamonds;
    }
}
.icon-users {
    &:before {
        content: $icon-users;
    }
}
.icon-eye {
    &:before {
        content: $icon-eye;
    }
}
.icon-heart {
    &:before {
        content: $icon-heart;
    }
}
.icon-cross {
    &:before {
        content: $icon-cross;
    }
}
.icon-checkmark {
    &:before {
        content: $icon-checkmark;
    }
}
.icon-enter {
    &:before {
        content: $icon-enter;
    }
}
.icon-volume-medium {
    &:before {
        content: $icon-volume-medium;
    }
}
.icon-volume-mute2 {
    &:before {
        content: $icon-volume-mute2;
    }
}
.icon-loop2 {
    &:before {
        content: $icon-loop2;
    }
}
.icon-checkbox-checked {
    &:before {
        content: $icon-checkbox-checked;
    }
}
.icon-checkbox-unchecked {
    &:before {
        content: $icon-checkbox-unchecked;
    }
}
.icon-radio-checked2 {
    &:before {
        content: $icon-radio-checked2;
    }
}
.icon-radio-unchecked {
    &:before {
        content: $icon-radio-unchecked;
    }
}
.icon-video {
    &:before {
        content: $icon-video;
    }
}
.icon-settings {
    &:before {
        content: $icon-settings;
    }
}
.icon-paperplane {
    &:before {
        content: $icon-paperplane;
    }
}
