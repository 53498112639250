@import 'src/styles/library/index';

.ModalComponent {
    $padding: px(2 * $basePadding);

    position: fixed;
    z-index: $zIndexModal;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    .App.audrey & {
        & .modal {
            @include auxiliaryMessageBaseLayout;
        }
    }

    & .backdrop {
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.5);
    }

    & .modal {
        position: relative;
        z-index: 2;
        display: grid;
        grid-template-rows: 1fr auto;
        width: 30rem;
        max-width: 90%;
        max-height: 60%;
        margin: {
            top: -6rem;
        }
        border: {
            width: 1px;
            style: solid;
            color: black;
            radius: 2 * $baseBorderRadius;
        }
        background: white;
    }

    & header {
        flex: 0 0 auto;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: $padding;
        padding: $padding;
        font: {
            size: 2rem;
            weight: 700;
        }
        border: {
            bottom: {
                width: 1px;
                style: solid;
                color: $colorGrey;
            }
        }

        & .cancel {
            font: {
                size: 1.5rem;
            }
            color: $colorGrey;
            cursor: pointer;

            .App.audrey & {
                display: none;
            }
        }
    }

    & .body {
        min-height: 0;
        max-height: 100%;
        max-width: 1150px;
        align-self: center;
        padding: $padding;
        font: {
            size: 1.5rem;
        }
        overflow: auto;
        word-break: break-word;

        & > div {
            &:not(:last-child) {
                margin-bottom: 0.6em;
            }
        }

        & p {
            margin: {
                bottom: px($basePadding);
            }
        }

        & .key {
            //display: block;
            font-weight: bold;
        }

        & .diamond,
        & .heart,
        & .spade,
        & .club {
            font-size: 0.9em;
        }

        & .diamond {
            color: var(--card-color-diamonds);
        }

        & .heart {
            color: var(--card-color-hearts);
        }

        & .spade {
            color: var(--card-color-spades);
        }

        & .club {
            color: var(--card-color-clubs);
        }
    }

    & footer {
        flex: 0 0 auto;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: #{2 * px($basePadding)} #{3 * px($basePadding)};
        border: {
            top: {
                width: 1px;
                style: solid;
                color: $colorGrey;
            }
        }

        & button,
        & a {
            .App.shark & {
                @include shark_dynamicButton;
            }

            .App.audrey & {
                padding: $padding/2 #{$padding};
                font-size: 1.2rem;
            }

            order: 1;
            color: black;
            text-transform: capitalize;
            margin: 0 #{px($basePadding)};
            white-space: nowrap;

            &.primary {
                order: 2;
            }
        }

        &.singleButton {
        }
    }

    & .AuctionBox {
        position: relative;
        margin: {
            bottom: 10px;
        }
    }

    &.manualDealModal {
        & .modal {
            width: 90%;
        }
    }

    &.makeClaimModal {
        & .body {
            & .claims {
                display: flex;
                flex-wrap: wrap;
                gap: 0.8rem;
                margin: {
                    top: $padding;
                }
            }

            & button {
                .App.shark {
                    @include shark_dynamicButton;
                }

                height: auto;
                width: 3rem;
                //margin: px($basePadding)/2 !important;
                padding: px($basePadding) 0;
                font-size: 1.2rem;
                color: black;
            }
        }
    }

    &.timeToTournamentModal {
        & .Countdown {
            margin: {
                left: px($basePadding);
            }
        }
    }

    &.NonBlockingSpalash {
        &.Modal {
            bottom: 25%;
            left: auto;
            right: auto;
        }

        & .backdrop {
            display: none;
        }
    }

    &.metaDataModal {
        & .modal {
            width: 650px;
        }

        & header {
            justify-content: center;

            & .cancel {
                display: none;
            }
        }

        & .tableResults {
            margin: {
                top: px($basePadding);
            }

            & table {
                width: 100%;
                margin: {
                    top: px($basePadding);
                }
                text-align: center;
            }

            & th,
            & td {
                border: 1px solid black;
                padding: px($basePadding) 0;
            }

            & th {
                font: {
                    weight: bold;
                }
            }

            & .card {
                text-transform: uppercase;

                &:before {
                    font: {
                        size: 2rem;
                    }
                }

                &.diamonds {
                    color: var(--card-color-diamonds);

                    &:before {
                        @include shark_icon($icon-diamonds);
                    }
                }

                &.hearts {
                    color: var(--card-color-hearts);

                    &:before {
                        @include shark_icon($icon-heart);
                    }
                }

                &.spades {
                    color: var(--card-color-spades);

                    &:before {
                        @include shark_icon($icon-spades);
                    }
                }

                &.clubs {
                    color: var(--card-color-clubs);

                    &:before {
                        @include shark_icon($icon-clubs);
                    }
                }
            }
        }

        & .conventionCards {
            display: none;

            & .conventionCard {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: {
                    bottom: px($basePadding);
                }

                & span {
                    &:after {
                        content: ': ';
                    }
                }
            }

            & .submitUrl {
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                flex-wrap: wrap;
                margin: {
                    top: 2 * px($basePadding);
                }

                & .LabeledInput {
                    flex: 0 0 100%;
                    margin: {
                        bottom: px($basePadding) / 2;
                    }
                }

                & .label {
                    color: $colorFont;
                }
            }

            & button {
                @include shark_dynamicButton;

                color: black;
            }
        }
    }

    &.contactDirectorModal {
        & .submitIssue {
            & .label {
                color: black;
            }
        }
    }

    &.conventionCardModal {
        & .modal {
            width: 100%;
            height: 100%;
            margin: 0;
            display: flex;
            flex-direction: column;
            overflow: hidden;
        }

        & .body {
            flex: 1 1 100%;
            width: 100%;
            padding: 0;
        }

        & iframe {
            width: 100%;
            height: 100%;
        }
    }

    &.modalGameResults {
        --isMeBottom: #{-1 * $padding};
    }

    &.gameResultsUrlModal {
        & .modal {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            max: {
                width: 100%;
                height: 100%;
            }
            margin: {
                top: 0;
            }

            & .body {
                flex: 1 1 100%;
                padding: 0;
            }

            & iframe {
                width: 100%;
                height: 100%;
            }
        }
    }

    &.showBoardReviewModal {
        & .modal {
            width: 100%;
            max: {
                width: 1200px;
                height: 100%;
            }
            margin: 0;

            & .body {
                overflow: auto;
                padding: {
                    right: 0;
                    left: 0;
                }
            }
        }
    }

    &.scoreModal {
        & .modal {
            width: auto;
            //max-width: 500px;
        }

        & .BoardStats {
            & .table {
            }
        }

        & .body {
            padding-top: 0;
        }
    }

    &.fullSize {
        & .modal {
            width: 100%;
            max-width: 100%;
            height: 100%;
            max-height: 100%;
            margin-top: 0;
        }

        & .body {
            max-width: none;
            align-self: start;
        }
    }

    & .auction-board-review {
        display: flex;
        flex-wrap: wrap;
        gap: px(30);
        width: fit-content;
        margin-left: auto;
        margin-right: auto;

        & .auctions {
            flex: 500px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: px(30);
            row-gap: px(15);
            align-self: start;
            max-width: 450px;

            & h3 {
                font-size: 22px;
                margin-bottom: 5px;
            }

            & .text {
                grid-column: 1 / 3;
                font-size: 18px;
            }

            & svg {
                --width: 70%;

                @include centerHV;

                position: absolute;
                width: var(--width);
            }

            & .SharkBidComponent.zero {
                font-size: 16px !important;
                padding: 0 !important;
            }

            &.noTrump {
                --width: calc(0.7 * var(--width));
                padding: 0 1rem;
            }

            & .bridgePosition {
                font-size: 18px !important;
            }

            @include breakpointBelow(600px) {
                & svg {
                    --width: 90%;
                }
                & .SharkBidComponent.zero {
                    font-size: 14px !important;
                    padding: 0 !important;
                }
                font-size: 1.2em !important;
            }
        }

        & .BoardReview {
            & .auctionBox-trickHistory,
            & .board-stats {
                display: none;
            }
        }
    }

    .Table.generalLayout-v_impaired ~ & {
        & .modal {
            width: 350px;
        }

        &.metaDataModal,
        &.timeToTournamentModal {
            & .modal {
                width: 600px;
            }
        }
    }
}
