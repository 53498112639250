@import 'src/styles/library/index';

.BiddingKeyboardComponent {
    @mixin biddingCell {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        vertical-align: middle;
        border: 1px solid black;
        cursor: pointer;

        &:after {
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        &:hover,
        &.highlighted {
            &:after {
                content: '';
                box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.5) inset;
            }
        }

        &.disabled {
            cursor: not-allowed;

            &:after {
                content: '';
                background: rgba(0, 0, 0, 0.5);
            }

            &:hover {
                &:after {
                    box-shadow: none;
                }
            }
        }
    }
    @mixin biddingTable {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }

    $biddingCellWidth: 50px;
    $biddingCellHeight: 50px;

    display: flex;
    flex-direction: column;

    & .bids {
        @include biddingTable;

        flex: 1 1 100%;

        & .biddingCell {
            @include biddingCell;

            background: white;
            overflow: hidden;
            height: 100%;

            & .SharkBidComponent {
                position: relative;
                width: 100%;
                height: 80%;
            }
        }
    }

    & .biddingActions {
        @include biddingTable;

        flex: 0 0 3rem;
        display: flex;
        width: 100%;

        & > div {
            @include biddingCell;

            flex: 0 0 100 / 3 * 1%;
            height: 100%;

            & .SharkBidComponent {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
            }
        }
    }

    & .selectedBid {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 10;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: $basePadding;
        background: white;

        & .SharkBidComponent {
            width: 70%;
            height: 30%;
            margin: {
                left: 13%;
            }

            &.noTrump,
            &.zero {
                margin: {
                    left: 0;
                }
            }

            &.zero {
                height: 5rem;
                line-height: 5rem;
                font-size: 2rem;
            }
        }

        & .confirmCancel {
            display: flex;
            justify-content: space-between;
            column-gap: 7%;
            width: 70%;
            margin: {
                top: 2rem;
            }
        }

        & .confirm {
            @include shark_buttonIconOnly($icon-checkmark);

            border: {
                color: green;
            }
            color: green;
        }

        & .cancel {
            @include shark_buttonIconOnly($icon-cross);

            border: {
                color: $colorRed;
            }
            color: $colorRed;
        }

        & .confirm,
        & .cancel {
            position: relative;
            flex: 0 0 50%;
            width: auto;
            height: auto;
            border: {
                width: 2px;
                style: solid;
            }
            aspect-ratio: 1;

            &:before {
                font-size: 2rem;
            }
        }
    }

    & .alertMessage {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: {
            top: 3 * $basePadding;
        }

        & input {
            @include dynInput;

            width: 100%;
            margin: {
                top: $basePadding;
            }
            border: {
                color: black;
            }
            color: black;
        }
    }

    & .auctionAlert {
        &.highlighted {
            & .SharkBidComponent {
                background: $colorRed;
            }
        }
    }
}
