.App {
    --buttonPaddingV: 0.25em;
    --buttonPaddingH: 0.4em;

    --fontFamily: 'noto', 'Verdana', 'Arial', sans-serif;

    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                   supported by Chrome, Edge, Opera and Firefox */

    position: relative;
    font-family: var(--fontFamily);

    & .RootComponent {
        min-height: 0;
    }

    & .FooterComponent {
        min-height: 0;
    }

    & .WalkThroughComponent,
    & .AuxiliaryMessageComponent,
    & .bridge-table-html {
        //    @import 'src/styles/legacy-styles';
        @import 'src/styles/overwrite-legacy-styles';
    }

    & .WalkThroughComponent {
        font-size: 1rem;
        @import 'src/styles/overwrite-legacy-WalkThroughComponent';
    }

    & .AuxiliaryMessageComponent {
        line-height: 110%;
        @import 'src/styles/overwrite-legacy-styles-AuxiliaryMessageComponent';
    }

    & .bridge-table-html {
        overflow: hidden;
        @import 'src/styles/overwrite-legacy-styles-bridge-table-html';
    }

    &.shark {
        --fontFamily: 'jost', 'Verdana', 'Arial', sans-serif;
    }
}

#root {
    & .LoadingComponent {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }
}

:root {
    // css variables
    --card-color-diamonds: #ff0000;
    --card-color-hearts: #ff0000;
    --card-color-spades: #000000;
    --card-color-clubs: #000000;
    //--card-color-background: #ffffff;
    //--dynamic-ratio: #{100vw / $dynamicBase};
    //--vh: 100vh;
    //--transition-time: 400ms;
}
