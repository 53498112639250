& table {
    &.ordinary {
        td {
            img {
                min-width: 80%;
                &.quiz_graphic {
                    min-width: 70%;
                }
                &.play_deal_graphic {
                    min-width: 70%;
                }
            }
        }
    }
}

& big {
    color: white;
    font-size: 3rem;
}
