@mixin tray {
    $startColor: rgba(0, 0, 0, 0);
    $endColor: $startColor;
    $step1Color: rgba(0, 0, 0, 0.1);
    $step2Color: $step1Color;
    $step1Percent: 30%;
    $step2Percent: 85%;

    position: relative;
    z-index: $z-seat + 2;
    padding: var(--trayPadding);
    border-radius: $borderRadius;
    background: #ccc;
    font-size: var(--trayFontSize);
    text-transform: capitalize;
    box-shadow: 0 0 var(--trayBoxShadow) rgba(0, 0, 0, 0.5);
    color: rgb(4, 16, 96);

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(
            180deg,
            $startColor 0%,
            $step1Color $step1Percent,
            $step2Color $step2Percent,
            $endColor 100%
        );
    }
}

@mixin shark_tray {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: 0 px($basePadding);
    border: {
        radius: 1rem 1rem 0 0;
        width: 1px;
        style: solid;
        color: black;
    }
    background: {
        color: var(--app-background, #ccc);
    }
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.5);
    color: white;
    font: {
        size: 1rem;
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
        border-radius: inherit;
    }
}

@mixin shark_icon($icon: null) {
    @if ($icon != null) {
        content: $icon;
    }
    font-family: '#{$icomoon-font-family}' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin shark_label {
    transition: all $base-transition-time ease-in-out;

    pointer-events: none;
    color: $color-grey-light;
    text-align: left;
    font-size: 13px;
    letter-spacing: 0.15px;
    line-height: 20px;
}

@mixin sharkTray {
    @include getFontSize(1);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: 1rem 0.5rem 0.5rem;
    border: {
        radius: 1rem 1rem 0 0;
        width: 1px;
        style: solid;
        color: black;
    }
    background: {
        color: var(--app-background);
    }
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.5);
    color: white;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
        border-radius: inherit;
    }
}

@mixin stackedCards {
    flex: 0 0 #{100 / $cards * 1%};

    &:not(:first-child) {
        margin-left: -($hOverlap / $cards * 100%);
    }
}

@mixin getFontSize($tableWidth) {
    font-size: 100 * 16 / 768 * $tableWidth * 1vh;
    @media only screen and (max-aspect-ratio: 4/3) {
        font-size: 100 * 16 / 1024 * $tableWidth * 1vw;
    }
}

@mixin contentColumns($walkThroughColumnWidth: 0, $bidColumnWidth: 0) {
    $tableWidth: 1 - $walkThroughColumnWidth - $bidColumnWidth;
    @include getFontSize($tableWidth);

    --tableWidth: #{$tableWidth * 100%};
    --walkThroughColumnWidth: #{$walkThroughColumnWidth * 100%};
    --bidColumnWidth: #{$bidColumnWidth * 100%};
}

@mixin dealerUnderline($height: px(3)) {
    &:before {
        content: '';
        position: absolute;
        bottom: px(-1);
        left: 0;
        right: 0;
        height: $height;
        background: #000066;
    }
}

@mixin auxiliaryMessageBaseLayout {
    --padding: #{px(5)};

    display: grid;
    grid-template-rows: 1fr;
    gap: var(--padding);
    padding: var(--padding);
    border-radius: px(15);
    border: px(5) solid #999999;
    background: #eae9fc;
    background: linear-gradient(90deg, #eae9fc 0%, #cdcdfb 100%);
    overflow: hidden;



    & > * {
        min-height: 0;
    }

    & .title {
        min-height: auto;
        margin: calc(-1 * var(--padding));
        margin-bottom: 0;
        padding: px(5);
        background-image: -webkit-gradient(
            linear,
            left bottom,
            right bottom,
            color-stop(0, rgb(202, 202, 254)),
            color-stop(1, rgb(79, 79, 252))
        );
        text-align: left;
        text-shadow: 0 0 2px rgba(255, 255, 255, 0.3);
        & .club{
            font-size: 1.3em;
        }
        & .heart{
            font-size: 1.3em;
            color: #c1394c;
        }
        & .diamond{
            font-size: 1.3em;
            color: #c1394c;
        }
        & .spade{
            font-size: 1.3em;
        }
    }

    & .text {
        @include getFontSize(1.3);
        overflow-y: auto;
        overflow-x: hidden;
        margin: 0;
        padding: 1px;
        border: none;
        width: 100%;

        & a.glossarylink {
            font-style: italic;
            text-decoration: none;
            color: inherit;
        }

        & .club{
            font-size: 1.3em;
        }
        & .heart{
            font-size: 1.25em;
            color: #c1394c;
        }
        & .diamond{
            font-size: 1.3em;
            color: #c1394c;
        }
        & .spade{
            font-size: 1.3em;
        }
    }
}

@mixin suitColors {
    $colors: (
        diamonds: --card-color-diamonds,
        hearts: --card-color-hearts,
        spades: --card-color-spades,
        clubs: --card-color-clubs,
    );

    @each $suit, $var in $colors {
        &.#{$suit} {
            color: var($var);
            border-color: var($var);

            & svg {
                & > g {
                    fill: var($var);
                }
            }

            & .face {
                border: {
                    color: var($var);
                }
            }
        }
    }
}

@mixin suits {
    @include suitColors;

    &.spades {
        &:before {
            @include shark_icon($icon-spades);
        }
    }

    &.hearts {
        &:before {
            @include shark_icon($icon-heart);
        }
    }

    &.diamonds {
        &:before {
            @include shark_icon($icon-diamonds);
        }
    }

    &.clubs {
        &:before {
            @include shark_icon($icon-clubs);
        }
    }
}

@mixin useTableBackground {
    @each $index, $background in (1: '../../../assets/images/table-1.jpg') {
        &.useBackground-#{$index} {
            background: {
                image: url($background);
            }
        }
    }
}

@mixin watermark {
    $sharkLogo: url('../../../../assets/images/shark_logo.png');

    background: {
        size: 0;
        repeat: no-repeat;
        image: var(--watermark, $sharkLogo);
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: {
            image: inherit;
            size: 65%;
            repeat: no-repeat;
            position: center 45%;
        }
        opacity: 0.05;
        pointer-events: none;
    }
}
