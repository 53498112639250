@import 'src/styles/library/index';

.TableComponent {
  overflow: hidden;
  --breakpointCardWidthMultiplier: 1;

  // card & hand
  --cardBaseWidth: #{px(90)};
  --cardBaseHeight: calc(var(--cardBaseWidth) * 328 / 227);
  --seatBaseHandHeight: calc(var(--cardBaseHeight) * 0.67);
  --verticalOverlap: -0.76;

  // tray
  --trayPadding: #{px(5)};
  --trayFontSize: #{px(30)};

  --auctionLineHeight: #{px(35)};
  --auctionLineHeight-top-height: #{auctionHeight('m')};
  --auctionLineHeight-right-height: #{auctionHeight('m')};
  --auctionLineHeight-bottom-height: #{auctionHeight('m')};
  --auctionLineHeight-left-height: #{auctionHeight('m')};
  --trayHeight: calc(2 * var(--trayPadding) + var(--trayFontSize) + var(--auctionLineHeight));
  --trayBoxShadow: #{px(3)};

  // seat
  --seatBaseComponentWidth-top: #{seatSize('m')};
  --seatBaseComponentWidth-right: #{seatSize('m')};
  --seatBaseComponentWidth-bottom: #{seatSize('m')};
  --seatBaseComponentWidth-left: #{seatSize('m')};

  --cardWidthMultiplier-top: calc(var(--breakpointCardWidthMultiplier) * var(--baseCardWidthMultiplier-top));
  --cardWidthMultiplier-right: calc(var(--breakpointCardWidthMultiplier) * var(--baseCardWidthMultiplier-right));
  --cardWidthMultiplier-bottom: calc(var(--breakpointCardWidthMultiplier) * var(--baseCardWidthMultiplier-bottom));
  --cardWidthMultiplier-left: calc(var(--breakpointCardWidthMultiplier) * var(--baseCardWidthMultiplier-left));

  --seatComponentWidth-top: calc(var(--seatBaseComponentWidth-top) * var(--cardWidthMultiplier-top));
  --seatComponentWidth-right: calc(var(--seatBaseComponentWidth-right) * var(--cardWidthMultiplier-right));
  --seatComponentWidth-bottom: calc(var(--seatBaseComponentWidth-bottom) * var(--cardWidthMultiplier-bottom));
  --seatComponentWidth-left: calc(var(--seatBaseComponentWidth-left) * var(--cardWidthMultiplier-left));

  --seatHandHeight-top: calc(var(--seatBaseHandHeight) * var(--cardWidthMultiplier-top));
  --seatHandHeight-right: calc(var(--seatBaseHandHeight) * var(--cardWidthMultiplier-right));
  --seatHandHeight-bottom: calc(var(--seatBaseHandHeight) * var(--cardWidthMultiplier-bottom));
  --seatHandHeight-left: calc(var(--seatBaseHandHeight) * var(--cardWidthMultiplier-left));

  --seatHeight: calc(var(--trayHeight) + var(--seatBaseHandHeight));
  --seatMargin: #{px(5)};
  --lrVerticalTop: 50%;

  // table info
  --tableInfoMargin: #{px(20)};

  // auxiliary messages
  --auxiliaryMarginH: var(--seatMargin);
  --auxiliaryMarginV: var(--seatMargin);

  position: relative;

  @mixin topBottomRightLeftSizes(
    $topBottom, // 'top' | 'bottom'
    $forceSize: null // 'width' | 'height' | 'both'
  ) {
    --maxHeight: calc(50% - var(--seatHeight) / 2 - 2 * var(--auxiliaryMarginV) - var(--trayBoxShadow));
    --maxWidth: calc(50% - var(--seatComponentWidth-#{$topBottom}) / 2 - 2 * var(--auxiliaryMarginH));

    max-width: var(--maxWidth);
    max-height: var(--maxHeight);

    @if ($forceSize == 'width' OR $forceSize == 'both') {
      width: var(--maxWidth);
    }

    @if ($forceSize == 'height' OR $forceSize == 'both') {
      height: var(--maxHeight);
    }
  }

  @mixin topRightLeftSizes(
    $forceSize: null // 'width' | 'height' | 'both'
  ) {
    @include topBottomRightLeftSizes('top', $forceSize);
  }

  @mixin bottomRightLeftSizes(
    $forceSize: null // 'width' | 'height' | 'both'
  ) {
    @include topBottomRightLeftSizes('bottom', $forceSize);
  }

  @mixin centerSizes {

    --maxWidth: calc(100% - var(--seatComponentWidth-left) - var(--seatComponentWidth-left));

  }

  & .close-trick {
    width: 100%;
    height: 100%;
  }

  & .SeatComponent {
    position: absolute;
    z-index: $z-seat;

    &.seat-position-top {
      @include centerH;

      --auctionLineHeight: var(--auctionLineHeight-top-height);
      --cardWidthMultiplier: var(--cardWidthMultiplier-top);
      --seatComponentWidth: var(--seatComponentWidth-top);
      --seatHandHeight: var(--seatHandHeight-top);

      top: var(--seatMargin);
    }

    &.seat-position-right {
      @include centerV;

      --auctionLineHeight: var(--auctionLineHeight-right-height);
      --cardWidthMultiplier: var(--cardWidthMultiplier-right);
      --seatComponentWidth: var(--seatComponentWidth-right);
      --seatHandHeight: var(--seatHandHeight-right);

      top: var(--lrVerticalTop);
      right: var(--seatMargin);
    }

    &.seat-position-bottom {
      @include centerH;

      --auctionLineHeight: var(--auctionLineHeight-bottom-height);
      --cardWidthMultiplier: var(--cardWidthMultiplier-bottom);
      --seatComponentWidth: var(--seatComponentWidth-bottom);
      --seatHandHeight: var(--seatHandHeight-bottom);

      z-index: $z-seat + 1;
      bottom: var(--seatMargin);
    }

    &.seat-position-left {
      @include centerV;

      --auctionLineHeight: var(--auctionLineHeight-left-height);
      --trayHeight: calc(2 * var(--trayPadding) + var(--trayFontSize) + var(--auctionLineHeight));
      --cardWidthMultiplier: var(--cardWidthMultiplier-left);
      --seatComponentWidth: var(--seatComponentWidth-left);
      --seatHandHeight: var(--seatHandHeight-left);

      top: var(--lrVerticalTop);
      left: var(--seatMargin);
    }

    &.seat-position-top.isVertical {
      & ~ .seat-position-right.isVertical,
      & ~ .seat-position-left.isVertical {
        --lrVerticalTop: 45%;
      }
    }

    &.seat-position-top:not(.isVertical) {
      & ~ .seat-position-right.isVertical,
      & ~ .seat-position-left.isVertical {
        top: calc(var(--seatHeight) + calc(var(--dummyVerticalMargin) * var(--seatMargin)));
        transform: none;
      }
    }
  }

  & .center-seat {
    &.seat-position-top {
      @include centerVH;
    }
  }

  & .SimpleTricksCounterComponent {
    position: absolute;
    bottom: var(--seatMargin);
    right: 0;
    padding: {
      right: var(--seatMargin);
      left: var(--seatMargin);
    }
  }

  & .TableInfoComponent {
    position: absolute;
    top: calc(var(--tableInfoMargin) * 2);
    max-width: calc((100% - var(--seat-component-top-width)) / 2 - 4 * #{var(--tableInfoMargin)});

    &.left {
      left: var(--tableInfoMargin);
    }

    &.right {
      right: var(--tableInfoMargin);
    }
  }

  & .AuxiliaryMessageComponent {
    position: absolute;
    z-index: $z-auxiliaryMessage;

    &.center {
      @include centerH;

      --top: calc(var(--seatHeight) + 2 * var(--auxiliaryMarginV) + var(--trayBoxShadow));

      top: var(--top);
      width: px(560);
      max-height: calc(75% - var(--seatHeight) / 8 - var(--top));
    }

    &.left,
    &.right {
      @include bottomRightLeftSizes;
    }

    &.left {
      bottom: var(--auxiliaryMarginV);
      left: var(--auxiliaryMarginH);
    }

    &.right {
      &:not(.bottom) {
         top: calc(100% - var(--maxHeight) - var(--auxiliaryMarginV));
      }

      right: var(--auxiliaryMarginH);
    }
  }

  & .bridge-table-html {
    position: absolute;
    z-index: $z-bridgeTableHtml;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1em;
    overflow: auto;
  }

  & .TrickCounterComponent,
  & .BiddingKeyboardComponent {
    position: absolute;
    bottom: var(--seatMargin);
    right: var(--trayBoxShadow);
  }

  & .TrickCounterComponent {
    @include bottomRightLeftSizes('width');
  }

  & .BiddingKeyboardComponent {
    @include bottomRightLeftSizes('both');
  }

  &.seat-size {
    $positions: top, right, bottom, left;
    $positions: top, right, bottom, left;
    $sizes: s, m, l, d;

    @each $position in $positions {
      &-#{$position} {
        @each $size in $sizes {
          &-#{$size} {
            #{--auctionLineHeight-#{$position}-height}: #{auctionHeight($size)};
            #{--seatBaseComponentWidth-#{$position}}: #{seatSize($size)};
          }
        }
      }
    }
  }

  &.bridge-table-hidden {
    & > *:not(.bridge-table-html) {
      display: none;
    }
  }

  @media (max-aspect-ratio: 1561 / 980) {
    --breakpointCardWidthMultiplier: 0.9;
  }

  /* @include breakpointBelow(630px) {
     --cardBaseWidth: 64px;
 }

 @include breakpointAbove(1600px) {
     --cardBaseWidth: 110px;
 }
 */
}
