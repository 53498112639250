@import 'src/styles/library/index';

.LoadingComponent {
    @include outline(white);
    @include tableBackground;

    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    font-size: 22px;

    & .suit {
        $animationTime: 1s;

        @include suits;

        display: inline-block;
        padding: 0 $basePadding/2;
        transform: translateY(0);
        animation: $animationTime jump infinite linear;

        @for $i from 1 through 4 {
            &:nth-child(#{$i}) {
                animation-delay: $i * $animationTime/20;
            }
        }
    }
}

@keyframes jump {
    0% {
        transform: translateY(0);
    }

    20% {
        transform: translateY(-100%);
    }

    40% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(0);
    }
}
