@import 'src/styles/library/index';

.TricksComponent {
    --cardWidthMultiplier: calc(var(--tableBaseCardWidthMultiplier, 1) * var(--breakpointCardWidthMultiplier, 1));
    --cardWidth: calc(var(--cardBaseWidth, 1) * var(--cardWidthMultiplier, 1));
    --cardHeight: calc(var(--cardBaseHeight, 1) * var(--cardWidthMultiplier, 1));

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $z-tricks;
    pointer-events: none;
}
