@import 'src/styles/library/index';

.SimpleTricksCounterComponent {
    --cardWidth: 5rem;
    --cardHeight: calc(var(--cardWidth) * 524 / 363);
    --bottomTrayWidth: 17.5rem;

    z-index: 202;
    width: calc(var(--cardWidth) * 5);

    & .tray {
        @include tray;

        display: flex;
        justify-content: space-between;
        align-items: center;
        height: px(60);
        padding: px(5);
        font-size: px(25);
        font-weight: bold;

        & > div {
            display: flex;
            align-items: center;
            column-gap: px(7);
        }
    }

    &.size-l {
        //--bottomTrayWidth: seatSize('l');
    }

    &.size-s {
        //--bottomTrayWidth: seatSize('s');
    }

    & .seat-hand {
        height: px(55);
        overflow: hidden;

        &-inner {
            position: relative;
            display: flex;
            z-index: 301;
            width: 100%;
            margin-left: 5%;
        }

        & .card-container {
            @include stackedCards;

            position: relative;

            & .CardComponent .card {
                cursor: pointer;
            }

            &.horizontal {
                & .CardComponent {
                    transform: rotate(90deg) translateY(-100%) translateX(#{px(20)});
                    transform-origin: top left;
                }
            }
        }
    }
}
