@import 'src/styles/library/index';

.SharkBidComponent {
    @include suitColors;

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    & svg {
        position: absolute;
        object-fit: contain;
        left: 13%;
        width: 100%;
        height: 100%;

        & > g {
            display: none;
        }
    }

    &.noTrump {
        & svg {
            left: 0;
        }
    }

    @each $number in ('one', 'two', 'three', 'four', 'five', 'six', 'seven') {
        &.#{$number} {
            & ##{$number} {
                display: block;
            }
        }
    }

    @each $suit in ('noTrump', 'spades', 'hearts', 'diamonds', 'clubs') {
        &.#{$suit} {
            & ##{$suit} {
                display: block;
            }
        }
    }

    &.xdbl,
    &.rdbl,
    &.redouble,
    &.auctionAlert,
    &.double,
    &.auctionStop,
    &.pass {
        text-align: center;
        color: white;
        font: {
            weight: 700;
            size: 1.5rem;
        }
    }

    &.redouble,
    &.rdbl,
    &.auctionAlert {
        background: $colorBlue;
    }

    &.double,
    &.xdbl,
    &.auctionStop {
        background: $colorRed;
    }

    &.pass {
        background: green;
    }
}
