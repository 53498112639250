@import 'src/styles/library/index';

@mixin animate($animation) {
    animation: $animation var(--animationDuration) ease-in 1;
}

@mixin keyframes($name) {
    @keyframes #{$name} {
        @content;
    }
}

@mixin laneAnimation($lane) {
    $animation: #{#{$lane}Lane};
    $oppositeSide: null;
    $endPos: null;
    $startPos: null;
    $endPosV: 46.5%;
    $endPosH: 49.5%;
    $startPosV: 80%;
    $startPosH: 80%;
    @if ($lane == top) {
        $oppositeSide: bottom;
        $endPos: $endPosV;
        $startPos: $startPosV;
    } @else if ($lane == right) {
        $oppositeSide: left;
        $endPos: $endPosH;
        $startPos: $startPosH;
    } @else if ($lane == bottom) {
        $oppositeSide: top;
        $endPos: $endPosV;
        $startPos: $startPosV;
    } @else if ($lane == left) {
        $oppositeSide: right;
        $endPos: $endPosH;
        $startPos: $startPosH;
    }

    @if ($oppositeSide != null) {
        #{$oppositeSide}: $endPos;
        @include keyframes($animation) {
            0% {
                #{$oppositeSide}: $startPos;
                opacity: 0;
            }
            50% {
                opacity: 1;
            }
            100% {
                #{$oppositeSide}: $endPos;
            }
        }
        @include animate($animation);
    } @else {
        content: 'incorrect $lane';
    }
}

.TrickComponent {
    position: absolute;
    top: 5%;
    right: 0;
    bottom: 0;
    left: 0;

    & .TrickLane {
        $laneShift: px(10);

        position: absolute;
        display: block;

        & .CardComponent {
            width: px(90);
        }

        &.top,
        &.bottom {
            @include centerH;
        }

        &.right,
        &.left {
            @include centerV;
        }

        &.top {
            @include laneAnimation(top);

            margin-left: -$laneShift;
        }

        &.right {
            @include laneAnimation(right);

            margin-top: -$laneShift;
        }

        &.bottom {
            @include laneAnimation(bottom);

            margin-left: $laneShift;
        }

        &.left {
            @include laneAnimation(left);

            margin-top: $laneShift;
        }
    }

    &.claimed {
        left: 70%;
        top: 48%;
        transform: scale(0.7);

        &:not(.show) {
            visibility: hidden;
        }
    }
}
