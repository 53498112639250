@import 'src/styles/library/index';

.AuxiliaryMessageComponent {
    @include auxiliaryMessageBaseLayout;

    &.hasButtons {
        grid-template-rows: 1fr auto;

        & div.buttons {
            display: block;
            margin: auto;

            & button {
                vertical-align: baseline;
                margin: 0 2px;
                margin-bottom: 5px;
                outline: none;
                cursor: pointer;
                text-align: center;
                text-decoration: none;
                font-size: 18px;
                font-weight: bold;
                text-transform: uppercase;
                -webkit-border-radius: 0.5em;
                border-radius: 0.5em;
                -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
                box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
                max-width: 100px;
                min-width: 100px;
                color: #606060;
                border: solid 1px #b7b7b7;
                background: #fff;
                background: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#ededed));
                background: -moz-linear-gradient(top, #fff, #ededed);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ededed');
            }
        }
    }

    &.bottom {
        bottom: 0;
    }

    & .bid {
        margin: 1rem auto;
        width: 7rem;
        height: 3rem;
        background: {
            position: center;
            repeat: no-repeat;
            size: contain;
        }
    }
}
