@import 'src/styles/library/index';

.TableSymbolComponent {
    position: absolute;
    top: var(--tableInfoMargin);
    left: var(--tableInfoMargin);
    width: 10rem;

    & svg {
        $size: 100%;

        width: $size;
        height: $size;
    }

    & .st0 {
        fill: #ffffff;
        stroke: #000000;
        stroke-miterlimit: 10;
    }

    & .st1 {
        fill: none;
    }

    & text {
        //@include baseFont;

        font: {
            size: 20px;
            weight: 700;
        }
    }

    & #center-label {
        display: block;
        font-size: 36px;
        text-anchor: middle;
        letter-spacing: -2px;
        font-weight: 400;
    }

    &.digit-3 {
        & #center-label {
            font-size: 30px;
        }
    }

    &.digit-4 {
        & #center-label {
            font-size: 25px;
        }
    }

    @each $segment,
        $list
            in (
                'left': (
                    right,
                    left,
                ),
                'top': (
                    top,
                    bottom,
                )
            )
    {
        @each $element in $list {
            &.v-#{$segment} {
                & ##{$element} {
                    & path {
                        fill: $colorRed;
                    }

                    & text {
                        fill: white;
                    }
                }
            }
        }
    }
}
