@function pxToEm($px) {
    @return strip-unit($px) / 16 * 1em;
}

@function getNextHigherBreakpoint($breakpoint) {
    $breakpointPx: map-get($breakpoints, $breakpoint);
    $nextBreakpoint: 0;
    @each $_breakpoint, $px in $breakpoints {
        @if ($px > $breakpointPx and $nextBreakpoint == 0) {
            $nextBreakpoint: $px;
        } @else if ($px > $breakpointPx and $px < $nextBreakpoint) {
            $nextBreakpoint: $px;
        }
    }
    @return $nextBreakpoint;
}

@function breakpointBelow($breakpointBelowPx) {
    @return #{'max-width: #{pxToEm($breakpointBelowPx) - pxToEm(1)}'};
}

@function breakpointAbove($breakpointAbovePx) {
    @return #{'min-width: #{pxToEm($breakpointAbovePx)}'};
}

@mixin breakpointBelow($breakpointBelowPx) {
    @media (breakpointBelow($breakpointBelowPx)) {
        @content;
    }
}

@mixin breakpointAbove($breakpointAbovePx) {
    @media (breakpointAbove($breakpointAbovePx)) {
        @content;
    }
}

@mixin breakpointBetween($lowerBreakpointPx, $upperBreakpointPx) {
    @media (breakpointAbove($lowerBreakpointPx)) and (breakpointBelow($upperBreakpointPx)) {
        @content;
    }
}

$breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
) !default;

@mixin breakpoint($breakpoint) {
    $dash: str-index($breakpoint, '-');
    @if ($dash == null) {
        $nextBreakpoint: getNextHigherBreakpoint($breakpoint);
        $breakpointPx: map-get($breakpoints, $breakpoint);
        @if ($nextBreakpoint == 0) {
            @include breakpointAbove($breakpointPx) {
                @content;
            }
        } @else if(strip-unit($breakpointPx) == 0) {
            @include breakpointBelow($nextBreakpoint) {
                @content;
            }
        } @else {
            @include breakpointBetween($breakpointPx, $nextBreakpoint) {
                @content;
            }
        }
    } @else {
        $lowerBreakpoint: str-slice($breakpoint, 1, $dash - 1);
        $upperBreakpoint: str-slice($breakpoint, $dash + 1, -1);

        @if ($upperBreakpoint == '*') {
            @include breakpointAbove(map-get($breakpoints, $lowerBreakpoint)) {
                @content;
            }
        } @else if ($lowerBreakpoint == '*') {
            $nextBreakpoint: getNextHigherBreakpoint($upperBreakpoint);
            @if ($nextBreakpoint == 0) {
                @include breakpointAbove(0) {
                    @content;
                }
            } @else {
                @include breakpointBelow($nextBreakpoint) {
                    @content;
                }
            }
        } @else if ($upperBreakpoint == '*' and $lowerBreakpoint == '*') {
            @include breakpointAbove(0) {
                @content;
            }
        } @else {
            $nextBreakpoint: getNextHigherBreakpoint($upperBreakpoint);
            @if ($nextBreakpoint == 0) {
                @include breakpointAbove(map-get($breakpoints, $lowerBreakpoint)) {
                    @content;
                }
            } @else {
                @include breakpointBetween(map-get($breakpoints, $lowerBreakpoint), $nextBreakpoint) {
                    @content;
                }
            }
        }
    }
}
