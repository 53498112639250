@import 'src/styles/library/index';

.ButtonComponent {
    .App.shark & {
        @include shark_dynamicButton;

        &.icon {
            @include shark_iconButton;

            &.nexthand {
                @include shark_buttonIconOnlyIcon($icon-chevron-right);

                &:before {
                    margin: {
                        top: 0.1rem;
                        left: 0.1rem;
                    }
                }
            }

            &.prevhand {
                @include shark_buttonIconOnlyIcon($icon-chevron-left);

                &:before {
                    margin: {
                        top: 0.1rem;
                        left: -0.1rem;
                    }
                }
            }

            &.showSharkSettings {
                @include shark_buttonIconOnlyIcon($icon-settings);
            }

            &.showInfo {
                @include shark_buttonIconOnlyIcon($icon-info);
            }
        }
    }

    .App.audrey & {
        @include button;
    }
}
