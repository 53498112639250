@mixin button {
    //@include getFontSize(1.2);

    position: relative;
    padding: var(--buttonPaddingV) calc(1.5 * var(--buttonPaddingH));
    cursor: pointer;
    border-width: 1px;
    border-color: #606060;
    color: black;
    border-radius: 0.3em;
    background: rgb(202, 204, 207);
    overflow: hidden;
    white-space: nowrap;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.25) 100%);
    }

    @media (hover: hover) {
        &:hover {
            background: #e6e6e6;
        }
    }

    &.highlighted {
        background: #e2e284;
    }

    @include breakpointBelow(900px) {
        font-size: 17.5px;
    }
}

// SHARK
@mixin shark_buttonDisabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed !important;
}

@function convert($px, $dynamic: false) {
    @if ($dynamic == true) {
        @return px($px);
    } @else {
        @return $px;
    }
}

@mixin shark_buttonBase($dynamic: false) {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: convert($baseButtonHeight, $dynamic);
    min-height: convert($baseButtonHeight, $dynamic);
    padding: 0 convert(3 * $basePadding, $dynamic);
    border: {
        width: 1px;
        style: solid;
        color: $colorFontInverted;
        radius: convert($baseBorderRadius * 2, $dynamic);
    }
    background: {
        color: transparent;
        image: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1));
    }
    color: $colorFontInverted;
    font: {
        size: convert(20px, $dynamic);
        weight: 500;
    }
    font-family: 'Jost', sans-serif;
    text-align: center;
    outline: none;
    vertical-align: middle;
    text-decoration: none;
    cursor: pointer;
    box-shadow: convert(5px, $dynamic) convert(5px, $dynamic) convert(10px, $dynamic) rgba(0, 0, 0, 0.5);

    &[disabled],
    &.disabled {
        @include shark_buttonDisabled;
    }

    &:hover {
        box-shadow: 0 0 0 transparent;
        background: {
            image: linear-gradient(180deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1));
        }
    }
}

// NORMAL BUTTONS
@mixin shark_button {
    @include shark_buttonBase;
}

@mixin shark_dynamicButton {
    @include shark_buttonBase(true);
}

@mixin shark_buttonWithIcon($icon, $iconPosition: left) {
    $iconSpace: $baseButtonHeight;

    position: relative;
    padding: {
        #{$iconPosition}: $iconSpace;
    }

    &:before {
        @include centerHV;

        @include shark_icon($icon);

        position: absolute;
        z-index: 2;

        @if ($iconPosition == left) {
            left: $iconSpace/2;
        } @else {
            left: calc(100% - #{$iconSpace/2});
        }
    }
}

// ICON BUTTONS
@mixin shark_iconButton {
    $size: 2.5rem;

    @include shark_buttonIconOnlyBase;

    position: relative;
    visibility: visible;
    flex: 0 0 $size !important;
    width: $size !important;
    height: $size !important;
    pointer-events: all;
    border: 1px solid white !important;
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1));
    color: white;
}

@mixin shark_buttonIconOnlyBase {
    transition: $baseTransition;
    border-radius: 50%;

    display: inline-block;
    width: px($baseButtonHeight);
    height: px($baseButtonHeight);
    padding: 0;
    background: none;
    color: $colorFont;
    line-height: 0;
    border: none;
    cursor: pointer;
    outline: none;

    &[disabled],
    &.disabled {
        @include shark_buttonDisabled;
    }
}

@mixin shark_buttonIconOnlyIcon($icon, $iconSize: null) {
    @if ($iconSize == null) {
        font-size: 1.3em;
    } @else {
        font-size: $iconSize;
    }

    & span {
        display: none;
    }

    &:before {
        @include centerHV;

        @include shark_icon($icon);

        position: absolute;
    }
}

@mixin shark_buttonIconOnly($icon, $iconSize: null) {
    @include shark_buttonIconOnlyBase;
    @include shark_buttonIconOnlyIcon($icon, $iconSize);
}

@mixin shark_resetButtonToIconOnly {
    padding: 0;
    border: none;
    background: none;
    outline: none;
    box-shadow: none;
}
