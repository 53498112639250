$weights: 100, 200, 300, 400, 500, 600, 700, 800, 900;
$fonts: 'noto', 'jost';
$styles: 'normal', 'italic';

@each $weight in $weights {
    @each $font in $fonts {
        @each $style in $styles {
            @font-face {
                font-family: #{$font};
                font-style: #{$style};
                font-weight: #{$weight};
                src: url('../../../fonts/#{$font}/#{$font}-#{$weight}-#{$style}.woff2') format('woff2');
            }
        }
    }
}
