@import 'src/styles/library/index';

.FooterComponent {
    display: grid;
    grid-template-columns: 2.1fr 1fr minmax(1.1fr, auto) minmax(1.1fr, auto) minmax(1.1fr, auto) 1fr 1fr 1fr 1.5fr 1fr 0.7fr 0.3fr;
    grid-template-areas: 'button-0 button-1 button-2 button-3 button-4 button-5 button-6 button-7 button-8 button-9 button-10 button-11';
    column-gap: 0.4em;
    background: rgb(241, 236, 234);
    // font-size: px(20);
    padding: var(--buttonPaddingV) var(--buttonPaddingH);
    color: black;
    @for $i from 0 through 10 {
        & .button-#{$i} {
            grid-area: button-#{$i};
        }
    }

    & .settings {
        grid-area: button-10;
    }

    & .card-button {
        grid-area: button-11;
    }

    &.OnlineBridgeClub {
        grid-template-columns: auto minmax(1fr, auto) minmax(1.1fr, auto) minmax(1.1fr, auto) minmax(1.1fr, auto) minmax(1.1fr, auto) minmax(1.1fr, auto) minmax(1.1fr, auto) minmax(1.5fr, auto) 1fr 0.7fr;
        grid-template-areas: 'button-0 button-1 button-2 button-3 button-4 button-5 button-6 button-7 button-8 button-9 button-10';
        & .settings {
            grid-area: button-10;
        }

        & .card-button {
            display: none;
        }
    }

    & > div {
        justify-self: center;
        width: 100%;

        &:first-child {
            justify-self: start;
        }
    }

    & .settings,
    & .card-button {
        position: relative;
        width: 100%;
    }

    & img {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
        cursor: pointer;
    }

    & .ButtonComponent {
        width: 100%;
        font-size: 20px;
        display: block;
    }
}
